import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import FeatureSectionLight from '../components/featureSectionLight';
import FeatureCardsSection from '../components/featureCardsSection';

import DevopsIcon from '../../assets/svg/uslugi-devops.svg';
import ProjektowanieIcon from '../../assets/svg/projektowanie-oprogramowania.svg';
import CloudIcon from '../../assets/svg/cloud.svg';
import AgileIcon from '../../assets/svg/agile.svg';
import MicroservicesIcon from '../../assets/svg/microservices.svg';
import NadzorIcon from '../../assets/svg/nadzor.svg';
import StartupsIcon from '../../assets/svg/startupy.svg';
import ERPIcon from '../../assets/svg/erp.svg';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import SEO from '../components/seo';

const TechPage = () => {
  const data = useStaticQuery(graphql`
        query ConsultingImages {
            kompleksowa: file(
                relativePath: { eq: "kompleksowa-usluga.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

  return (
    <SubLayout
      title={(
        <>
          <span>IT</span>
          {' '}
          Consulting
        </>
              )}
      subtitle="Tailored consulting services"
      img="/img/bg-systemy-erp.jpg"
    >
      <SEO title="IT consulting services at iqcode Software House" description="iqcode offers specialized IT consulting services in the areas of software project requirements engineering, software development supervision and SaaS startup evaluation." />
      <FeatureSectionLight
        title={(
          <>
            Software
            {' '}
            <span>Consulting</span>
          </>
                      )}
        img={(
          <div style={{ padding: '10%' }}>
            <Img
              alt="specialized software consulting services"
              fluid={data.kompleksowa.childImageSharp.fluid}
              imgStyle={{ width: 'auto' }}
            />
          </div>
                      )}
        desc={(
          <>
            <p>
              We offer customer-tailored IT consulting services in numerous areas of expertise. We conduct in-depth analysis of the client's needs and document functional and non-functional requirements of software projects. A wide variety of software development technologies that we use allows us to offer complex consulting services cross-cutting numerous areas of IT expertise.
            </p>
            <p>
              Our key consulting areas are tech startup evaluation, digital products requirements engineering and supervision over  software development projects safeguarding the client's interests.
            </p>
          </>
                      )}
        link="Let's talk"
        to="/contact/"
      />
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Let's discuss your
            {' '}
            <span>project</span>
            !
          </h3>
          <Link className="btn" to="/contact/">
            Contact
          </Link>
        </div>
      </div>
      <FeatureCardsSection
        lg
        title={(
          <>
            Areas of
            {' '}
            <span>Expertise</span>
          </>
                  )}
        subtitle="In which we offer consulting services"
        cards={[
          {
            title: 'Startup Evaluation',
            desc:
  <>
    Services provided to venture capital firms at the
    due diligence stage of tech startup mergers and acquisitions transactions. We specialize in assessing SaaS products from the point of view of their ongoing development costs, identification of potential technical debt and evaluation of their technical scalability.
  </>,
            icon: <StartupsIcon />,
          },
          {
            title: 'Public Cloud',
            desc:
  <>
    Consulting services regarding migration from on-premise infrastructure to public cloud environments (including Amazon Web Services and Microsoft Azure) as well as custom-designed hybrid cloud solutions.
  </>,
            icon: <CloudIcon />,
          },
          {
            title: 'DevOps Services',
            desc:
  <>
    Services related to creation and maintenance of continuous integration and continuos deployment environments, adoption of container technologies and implementation of Kubernetes distributed systems environment including proper logging and monitoring of production workloads.
  </>,
            icon: <DevopsIcon />,
          },
          {
            title: 'Agile Methodologies',
            desc:
  <>
    Consulting services aiding implementation of agile development methodologies (especially
    {' '}
    <strong>Scrum</strong>
    ) in client's software development teams with optional, temporary or permanent take over of the (Proxy) Product Owner or Scrum Master roles by iqcode.
  </>,
            icon: <AgileIcon />,
          },
          {
            title: 'Microservices Architecture',
            desc:
  <>Consulting and solutions design related to transforming existing, monolithic server-side applications into microservices architecture using both synchronous communication solutions (Istio, gRPC) and asynchronous ones (RabbitMQ, Apache Kafka) in a distributed system.</>,
            icon: <MicroservicesIcon />,
          },
          {
            title: 'CRM/ERP Systems',
            desc:
  <>Services related to implementation of existing CRM/ERP systems in client's businesses including analysis and modelling of business processes, creation of documentation and functional requirements as well as advisory in choosing an appropriate system.</>,
            icon: <ERPIcon />,
          },
          {
            title: 'Software Design',
            desc:
  <>
    Consulting services in the area of software design including conducting
    {' '}
    <i>Design Sprint</i>
    workshops, creation of application architecture schemes, formulation of functional and non-functional requirements and creation of appropriate project documentation aiming to allow the client acquire fixed-price offers from contractors.
  </>,
            icon: <ProjektowanieIcon />,
          },
          {
            title: 'Supervision over Software Projects',
            desc:
  <>Our software development supervision services aim to safeguard the client's interest when outsourcing software development to external contractors by creating appropriate project documentation, conducting periodical source code maintainability assessment, performing acceptance testing and assisting the client in the project approval stage.</>,
            icon: <NadzorIcon />,
          },
        ]}
      />
      <AllServices />
    </SubLayout>
  );
};

export default TechPage;
